<template>
  <div class="wrapper">
    <van-nav-bar
      :title="title"
      :left-arrow="isBack"
      @click-left="leftAction"
    ></van-nav-bar>
    <div
      class="main_content"
      :class="className"
      :style="{ height: !isDetail ? 'calc(100% - 94px)' : 'calc(100% - 44px)' }"
    >
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <van-tabbar
      v-model="activeIndex"
      v-if="!isDetail"
      active-color="#0F60A7"
      inactive-color="#000"
    >
      <van-tabbar-item icon="index" name="index">首页</van-tabbar-item>
      <van-tabbar-item icon="agency" name="agency" :badge="todo_num"
        >待办</van-tabbar-item
      >
      <van-tabbar-item icon="personalCenter" name="personalCenter"
        >我的</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Dialog } from "vant";
import Http from "../../../api/http";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const title = ref(
      route.params.title ? route.params.title : route.meta.title
    );
    const isBack = ref(route.meta.isBack);
    const activeIndex = ref(route.meta.activeIndex);
    const isDetail = ref(route.meta.isDetail);
    const className = ref(route.meta.className || "");
    const isHasButton = ref(route.meta.isHasButton || false);

    const leftAction = () => {
      var meta = route.meta;
      var params =
        route.params && route.params.parentType ? route.params : null;
      router.push({
        name: meta.parent,
        params: params
          ? {
              id: params.id,
              type: params.parentType,
              title: params.parentTitle
            }
          : null
      });
    };
    // 获取待办和报警数量
    const todo_num = ref("");
    const alert_num = ref("");
    const getBadge = () => {
      Http.get(
        {
          method: "query",
          queryId: 181
        },
        false,
        false
      )
        .then(res => {
          var obj = res.map[0] || { todo_num: 0, alert_num: 0 };
          todo_num.value = obj.todo_num;
          alert_num.value = obj.alert_num;
        })
        .catch(() => {});
    };
    const goTo = path => {
      router.push(path);
    };

    onMounted(() => {
      getBadge();
    });
    // 退出登录
    const loginOut = () => {
      Dialog.confirm({
        message: "此操作将退出登录，是否继续?"
      })
        .then(() => {
          localStorage.removeItem("app_user_mobile");
          localStorage.removeItem("app_organization_id");
          localStorage.removeItem("app_version");
          localStorage.removeItem("app_user_name");
          localStorage.removeItem("app_user_email");
          localStorage.removeItem("app_user_id");
          localStorage.removeItem("app_user_organization");
          localStorage.removeItem("app_user_department");
          localStorage.removeItem("app_token");
          router.push("/Login");
        })
        .catch(() => {});
    };
    watch(
      () => activeIndex.value,
      (newval, oldval) => {
        if (newval != oldval) {
          router.push(newval);
        }
      }
    );
    // watch(()=>route,()=>{
    //   debugger
    //   if(route.meta.isDetail){
    //       title.value = route.params.title?route.params.title:route.meta.title
    //     }else{
    //       title.value = route.meta.title
    //     }
    //     className.value = route.meta.className || ''
    //     isBack.value = route.meta.isBack
    //     activeIndex.value = route.meta.name
    //     isDetail.value = route.meta.isDetail
    //     isHasButton.value = route.meta.isHasButton ? route.meta.isHasButton :false
    // });

    return {
      title,
      isBack,
      activeIndex,
      isDetail,
      isHasButton,
      leftAction,
      loginOut,
      getBadge,
      todo_num,
      alert_num,
      className,
      goTo
    };
  },
  watch: {
    $route(route) {
      if (route.meta.isDetail) {
        this.title = route.params.title ? route.params.title : route.meta.title;
      } else {
        this.title = route.meta.title;
      }
      this.className = route.meta.className || "";
      this.isBack = route.meta.isBack;
      this.isRightAction = route.meta.isRightAction;
      this.activeIndex = route.meta.activeIndex;
      this.isDetail = route.meta.isDetail;
      this.isHasButton = route.meta.isHasButton
        ? route.meta.isHasButton
        : false;
    }
  }
  // created(){
  //   var $this = this
  //   window.addEventListener('setItem', (e) => {
  //       console.log(e);
  //       if(e.key == 'pageTitle'){
  //         $this.title = e.newValue;
  //       }
  //   });
  // }
};
</script>
